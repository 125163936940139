<template>
  <div
    class="home"
    v-bind:class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-0 px-0'"
    style="margin-bottom: 50px; margin-top: 80px"
  >
    <v-row no-gutters justify="center" class="mt-12 black--text">
      <v-col v-bind="main_card_width">
        <v-card color="transparent" class="pa-2">
          <v-card-text>
            <v-row class="rounded_row_white black--text pa-2 py-4 pb-12">
              <v-col cols="12" sm="4" v-if="!$vuetify.breakpoint.smAndDown">
                <v-img
                  :src="require('@/assets/imgs/pages/firewall.png')"
                  alt="Icona firewall"
                  class="service_image_position"
                  contain
                  max-width="200"
                  :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                ></v-img>
              </v-col>
              <v-col cols="12" sm="7">
                <h1 class="service_title">Dentro i buoni, fuori i cattivi.</h1>
                <div class="small_screen_service_image">
                  <v-img
                    v-if="$vuetify.breakpoint.smAndDown"
                    :src="require('@/assets/imgs/pages/firewall.png')"
                    alt="Icona firewall"
                    class="mt-2 mb-6"
                    contain
                    max-width="200"
                    :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                  ></v-img>
                </div>
                <h2 class="service_subtitle">
                  Riparati dalle minacce di Internet!
                </h2>
                <p>
                  I firewall sono soluzioni che, se opportunamente configurate,
                  consentono di monitorare il traffico in ingresso e in uscita
                  dalla tua azienda.
                </p>
                <p>
                  Questi dispositivi integrano al loro interno regole di
                  sicurezza capaci di intervenire per consentire, bloccare o
                  limitare determinati eventi potenzialmente dannosi.
                </p>
                <p>
                  La famiglia dei firewall è composta sia da dispositivi
                  hardware, a difesa della tua infrastruttura di rete aziendale,
                  sia da soluzioni software, a bordo dei dispositivi, per
                  garantire protezione costante anche al di fuori della tua
                  azienda.
                </p>
                <p>
                  Proteggi i tuoi dati e i tuoi sistemi da accessi non
                  autorizzati e da tentativi di intrusione provenienti
                  dall'esterno.
                </p>
              </v-col>

              <v-col cols="12" class="text-center">
                <contactCardButton
                  contact_type="firewall"
                  title="Vuoi mettere in sicurezza le tue infrastrutture e i tuoi dati?"
                />
              </v-col>

              <!-- <v-col sm="7" offset-sm="4" cols="12" class="text-center">
                <v-btn
                  :to="{ name: 'Contacts', params: { topic: 'firewall' } }"
                  outlined
                  block
                >
                  Contattaci
                </v-btn>
              </v-col> -->

              <!-- <v-col cols="12" class="text-center">
                <v-card
                  small
                  text
                  color="white black--text"
                  :to="{ name: 'OurServicesAssistenzaConsulenza' }"
                  flat
                >
                  <v-card-actions>
                    <v-spacer />
                    Torna all'elenco dei servizi
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-col> -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import contactCardButton from "@/components/contactCardButton";

export default {
  components: {
    contactCardButton,
  },
  metaInfo: {
    title: "Firewall & Cybersecurity",
    meta: [
      {
        name: "description",
        content:
          "Proteggi i sistemi della tua infrastruttura e metti al riparo i tuoi dispositivi dalle minacce provenienti da Internet.",
      },
      {
        name: "robots",
        content: "index",
      },
    ],
  },
};
</script>